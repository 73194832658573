import { defineStore } from 'pinia'
import user from "~/pages/user.vue";
import type {User} from "~/types/User";

export const useNotificationsStore = defineStore('notificationsStore', () => {
    const unseenNotifications = ref(0)

    async function loadUnseenCount() {
        const response = await $fetch('/api/notifications/unseen-count')
        if (response) {
            unseenNotifications.value = response.unseenCount
        }
    }

    async function loadNotification(notificationUid) {
        return useAsyncData(
            'loadNotification'+notificationUid,
            () => $fetch('/api/notifications/' + notificationUid)
        )
    }
    async function markNotificationSeen(notificationUid: string) {
        unseenNotifications.value = unseenNotifications.value - 1
        return $fetch('/api/notifications/' + notificationUid + '/seen', {
            method: 'POST',
        })
    }

    return {
        unseenNotifications,
        loadUnseenCount,
        markNotificationSeen,
    }
})
